<template>
  <transition name="slide-fade">
    <div>
      <Header :username="username" />
      <div class="bgimage">
        <v-img
          v-if="isEnergy"
          src="../assets/images/Energy_Oil-min.jpeg"
          height="100%"
          max-width="100%"
        ></v-img>
        <v-img
          v-if="!isEnergy"
          src="../assets/images/Adobe1-min.jpeg"
          height="100%"
          max-width="100%"
        ></v-img>
      </div>
      <div class="component">
        <div class="main">
          <v-expand-transition>
            <div class="message" v-show="show_message">
              <v-alert
                class="ma-1"
                border="top"
                style="background-color: #16ae4e"
              >
                {{ message }}
              </v-alert>
            </div>
          </v-expand-transition>
          <v-form id="profileform" @submit.prevent="submit">
            <div class="profile-container" style="padding-top: 30px">
              <div class="row">
                <div class="cell">
                  <v-text-field
                    label="First Name"
                    :value="userdata.first_name"
                    disabled="disabled"
                  ></v-text-field>
                </div>
                <div class="cell">
                  <v-text-field
                    label="Last Name"
                    :value="userdata.last_name"
                    disabled="disabled"
                  ></v-text-field>
                </div>
              </div>
              <div class="row">
                <div class="cell">
                  <v-text-field
                    label="Phone number"
                    disabled="disabled"
                    :value="userdata.phone_mobile"
                  ></v-text-field>
                </div>
                <div class="cell">
                  <v-text-field
                    label="Email"
                    name="email1"
                    :value="userdata.email1"
                    disabled="disabled"
                  ></v-text-field>
                </div>
              </div>
              <v-divider />
              <h5 style="color: gray">NOTIFICATIONS</h5>
              <div class="row">
                <div class="cell">
                  <v-checkbox
                    name="document_notifications_c"
                    class="checkbox"
                    v-model="userdata.document_notifications_c"
                    :disabled="!edit || loading"
                  >
                    <template v-slot:label>
                      <span style="margin-left: 7px"
                        >New Documents Notification</span
                      >
                    </template>
                  </v-checkbox>
                </div>
              </div>
              <br />
            </div>
          </v-form>
          <br /><br /><br />
          <div class="row btns">
            <div class="cell">
              <v-btn v-if="edit" style="color: gray" @click="edit = false"
                >Cancel</v-btn
              >
            </div>
            <div class="cell">
              <v-btn
                v-if="!edit"
                :disabled="fetching_data"
                style="background-color: #07aeef"
                color="#ffffff"
                @click="edit = true"
                >Edit</v-btn
              >

              <v-btn
                v-if="edit"
                style="background-color: #08ac4e"
                @click="submit"
                :loading="loading"
                type="submit"
                >Save</v-btn
              >
            </div>
            <br /><br /><br />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Header from "./Header.vue";
import http from "../http-common";

export default {
  components: {
    Header,
  },
  data() {
    return {
      fetching_data: true,
      username: "",
      userdata: {},
      edit: false,
      loading: false,
      show_message: false,
      message: "",
      isEnergy: false,
    };
  },
  mounted: function () {
    const isEnergyStorage = localStorage.getItem("isEnergy");
    this.isEnergy = isEnergyStorage == "true" ? true : false;

    let userdata = JSON.parse(localStorage.getItem("PortalMe"));
    // let Admndata = JSON.parse(localStorage.getItem("AdminAuth"));

    if (userdata === null) {
      console.log("Not authenticated");
      window.location.href = "/login?redirect=profile";
    }

    this.username = userdata.current_user.full_name;
    this.getContact(userdata.current_user.id)
      .then((response) => {
        console.log("Contacto", response.data);
        this.fetching_data = false;
        this.userdata = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    getContact(id) {
      const config = {
        withCredentials: true,
      };
      return http.get(`/getContact/${id}`, config);
    },
    submit(e) {
      e.preventDefault();
      this.loading = true;

      // const form = document.getElementById("profileform");
      // const formData = new FormData(form); // reference to form element
      // let data = {}; // need to convert it before using not with XMLHttpRequest
      // for (let [key, val] of formData.entries()) {
      //     data[key] = val;
      //     console.log(val);
      //     // Object.assign(data, { [key]: val })
      // }
      // const checkbox = document.getElementsByName("document_notifications_c");

      const data = {
        document_notifications_c: this.userdata.document_notifications_c,
        email_only_c: this.userdata.email_only_c,
        text_c: this.userdata.text_c,
        phone_call_c: this.userdata.phone_call_c,
        mailing_c: this.userdata.mailing_c,
      };

      console.log(data);

      const settings = {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      };

      http
        .put(`/Contact/${this.userdata.id}`, data, settings)
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.userdata = response.data;
          this.message = "Information update correctly";
          this.show_message = true;
          this.edit = false;
        })
        .catch((error) => {
          this.loading = false;
          this.show_message = true;
          this.edit = false;

          this.message = error;
        });
    },
  },
};
</script>

<style scoped>
.bgimage {
  position: fixed;
  top: 1;
  /* background: red; */
  width: 100%;
  height: 100%;
}
.component {
  z-index: 10000;
  background-color: #ffffff in !important;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 2rem;
}

.message {
  width: 100%;
}

.v-alert {
  color: #ffffff;
  width: 100%;
}

.main {
  background-color: #ffffff;
  display: flex;
  z-index: 10001;
  border: 1px solid #c2c2c2;

  width: 80%;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.btns {
  width: 80% !important;
}

.main form {
  display: flex;

  width: 80%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.v-btn {
  width: 100%;
  color: azure;
  font-weight: bold;
}

.profile-container {
  width: 100%;
}

.row {
  width: 100%;
}

.checkbox1 {
  /* margin-right: -10px; */
  font-weight: bold;
}

.cell {
  width: 50%;
}
</style>
